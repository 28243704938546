if (!String.prototype.replaceArray) {
	String.prototype.replaceArray = function(find, replace) {
		var replaceString = this;
		var regex;
		for (var i = 0; i < find.length; i++) {
			regex = new RegExp(find[i], "g");
			replaceString = replaceString.replace(regex, replace[i]);
		}
		return replaceString;
	};
}

(function($) {

	var element = '[data-post-list-entry]',
		increment = 12,
		$count, $count_list, $count_search,
		$noresults,
		$postlist_container,
		$search_field,
		$list,
		$searchresults,
		$filters = {},
		filter_term = '',
		$load_more,
		more_to_load = -1,
		postid_matches = {},
		$api_link = null,
		api_root = null,
		card_template = '';

	/**
	 * Decides whether the current list of cards is a set of search results or not
	 * @return boolean Yes or no! ;)
	 */
	var isSearchResults = function() {
		return $postlist_container.hasClass('is-search-results');
	};

	/**
	 * (Re-)initialises the isotope layout
	 */
	var initIsotope = function() {
		if ($.fn.isotope) {
			if ($list.data('isotope')) {
				$list.isotope('destroy');
			}
			$list.isotope({
				itemSelector: '.card_holder:not(.hidden)'
			});
			if ($searchresults.data('isotope')) {
				$searchresults.isotope('destroy');
			}
			$searchresults.isotope({
				itemSelector: '.card_holder:not(.hidden)'
			});
		}
	};

	/**
	 * Saves selected values in filters so that they can be restored
	 * if we change to another page and then change back again
	 */
	var storeSelection = function() {
		try {
			var selection = {};
			$filters.each(function() {
				if ($(this).data('filter') !== '') {
					selection[$(this).data('postlist-filter')] = $(this).val();
				}
			});
			localStorage.setItem('post_list_filter', 'post_list_filter');
			localStorage.removeItem('post_list_filter');
			localStorage.setItem('post_list_filter', JSON.stringify(selection));
			return true;
		} catch (e) {
			if (window.console) {
				window.console.error(e);
			}
		}
	};

	/**
	 * Fetches precog values from localStorage and sets the filter
	 * pulldowns according to previously stored values
	 */
	var getPrecog = function() {
		try {
			if ((precogs = JSON.parse(localStorage.getItem('post_list_filter')))) {
				$.each(precogs, function(precog_key, precog_value) {
					$filters.filter('[data-postlist-filter="' + precog_key + '"]').each(function() {
						$(this).val(precog_value);
					});
				});
				$filters.trigger('change.listfilter');
			}
		} catch (e) {
			if (window.console) {
				window.console.error(e);
			}
		}
	};

	/**
	 * Shows the number of currently visible entries in the predefined
	 * HTML container element.
	 */
	var updateCountIndicator = function() {
		if (isSearchResults()) {
			$('[data-postlist-counter]', $count_search).html($(element + ':not(.hidden)', $searchresults).length);
		} else {
			if (filter_term !== '') {
				$('[data-postlist-counter]', $count_list).html($(element + '.' + filter_term + ':not(.hidden)', $list).length + ' / ' + $(element + '.' + filter_term, $list).length);
			} else {
				$('[data-postlist-counter]', $count_list).html($(element + ':not(.hidden)', $list).length + ' / ' + $(element).length);
			}
		}
	};

	/**
	 * Updates the list to match the selected filter
	 * (Hides entries which don't match)
	 */
	var filterList = function(event) {

		// Combine all of the individual class names to make a single string,
		// which will then be compared against the class names on the elements
		filter_terms = [];
		$filters.each(function() {
			if ($(this).data('postlist-filter') !== 'search' && (filter_value = $(this).val().replace(/^\./g, '')) !== '') {
				filter_terms.push(filter_value);
			}
		});
		filter_term = filter_terms.join('.');

		scrollTop = $(window).scrollTop();

		if (event.data && event.data.source !== 'button') {
			$(element).addClass('hidden');
			more_to_load = $(element + '.hidden').length;
		}

		if (filter_term === '') {
			$(element + '.hidden').slice(0, increment).removeClass('hidden');
			more_to_load = $(element + '.hidden').length;
		} else {
			$(element + '.hidden.' + filter_term).slice(0, increment).removeClass('hidden');
			more_to_load = $(element + '.hidden.' + filter_term).length;
		}

		if (more_to_load <= 0) {
			$load_more.attr('disabled', true).text($load_more.data('text-alt'));
		} else {
			$load_more.attr('disabled', false).text($load_more.data('text'));
		}

		updateCountIndicator();

		storeSelection();

		$(window).trigger('ct:filteredpostlist:listupdated');

		// correct scroll position because of hiding all elements each load
		if (event.data && event.data.source === 'button') {
			$(window).scrollTop(scrollTop);
		}

	};

	var clearSearchResults = function() {
		$postlist_container.removeClass('is-search-results');
		$searchresults.empty().removeClass('row');
		$search_field.data('term_was', '');
		$noresults.hidden();
		$count.nothidden();
		$filters.trigger('change.listfilter', { source: 'filter' });
		$(window).trigger('ct:filteredpostlist:listupdated');
	};

	var noSearchResults = function() {
		$search_field.searchunwait();
		$search_clear.nothidden();
		clearSearchResults();
		$count.hidden();
		$noresults.nothidden();
		$(window).trigger('ct:filteredpostlist:issearch');
	};

	var isNotSearch = function() {
		clearSearchResults();
		$search_field.val('');
	};

	var initSearch = function() {
		$search_field.data('term_was', $search_field.val());
		$search_field.on('keyup.dosearch', function(event) {
			if (event.keyCode === 13) {
				$(this).runsearch();
			}
		});
	};

	var showSearchResults = function(event, results) {
		var resultCards = '';
		var template = Twig.twig({
		    data: card_template
		});
		$.each(results.data, function() {
			resultCards += template.render({
				data: {
					ID: this.ID,
					filterClass: this.filterClass,
					permalink: this.permalink,
					post_date: this.post_date_legible,
					phase: this.phase,
					states: this.states,
					post_title: this.post_title,
					people: this.people,
					meta: this.meta
				}
			});
		});
		clearSearchResults();
		$searchresults.addClass('row').html(resultCards);
		$(window).trigger('ct:filteredpostlist:resultsfound');
	};

	/**
	 * Activate the search functionality
	 * September 2017 mhm
	 */
	var activateSearch = function() {

		$search_clear.on('click.clearsearch', function() {
			clearSearchResults();
			$search_field.val('').focus();
		});

		// Show/hide the helper text
		$search_field.on('focus.tip', function() {
			$(this).closest('.custom-keyword-search').addClass('with--tip');
			$search_clear.nothidden();
		}).on('blur.tip', function() {
			$(this).closest('.custom-keyword-search').removeClass('with--tip');
			if (!$search_field.val()) {
				$search_clear.hidden();
				clearSearchResults();
			}
		});

		$(window).on('ct:filteredpostlist:listupdated', function(e) {
			initIsotope();
			updateCountIndicator();
		});

		$(window).trigger('ct:filteredpostlist:listupdated');

		$(window).on('ct:filteredpostlist:issearch', function() {
			$postlist_container.addClass('is-search-results');
		});

		$(window).on('ct:filteredpostlist:resultsfound', function() {
			$search_field.searchunwait();
			$search_clear.nothidden();
			$postlist_container.addClass('is-search-results');
			$noresults.hidden();
			$count.nothidden();
			$search_field.focus();
			$(window).trigger('ct:filteredpostlist:listupdated');
			$filters.trigger('change.listfilter', { source: 'filter' });
		});

		initSearch();
	};

	function onSearchSuccess(results) {

		var resultCards = '';
		var template = Twig.twig({
		    data: card_template
		});
		
		$.each(results, function() {
			resultCards += template.render({
				data: {
					ID: this.ID,
					filterClass: this.filterClass,
					permalink: this.permalink,
					post_date: this.post_date_legible,
					phase: this.phase,
					states: this.states,
					post_title: this.post_title,
					people: this.people,
					meta: this.meta
				}
			});
		});

		clearSearchResults();
		$searchresults.addClass('row').html(resultCards);
		$(window).trigger('ct:filteredpostlist:resultsfound');
	}

	function onSearchError(result) {
		clearSearchResults();
		$(window).trigger('ct:filteredpostlist:resultsfound');
	}

	function ctSearch(query){
		var restBase = document.location.protocol + '//' + document.location.hostname + '/wp-admin/admin-ajax.php';
		var data = {
			query: query,
			action: 'ajax_search_posts'
		};

		$.ajax({
			success: onSearchSuccess,
			error: onSearchError,
			url: restBase,
			dataType: 'json',
			method: 'GET',
			data: data
		});
	}

	/**
	 * jQuery extender functions
	 */

	if (!$.fn.searchwait) {
		$.fn.extend({
			searchwait: function() {
				$(this).attr('disabled', 'disabled');
				$(this).addClass('is-waiting');
				return this;
			}
		});
	}

	if (!$.fn.searchunwait) {
		$.fn.extend({
			searchunwait: function() {
				$(this).removeAttr('disabled');
				$(this).removeClass('is-waiting');
				return this;
			}
		});
	}

	if (!$.fn.hidden) {
		$.fn.extend({
			hidden: function() {
				$(this).addClass('is-hidden');
				return this;
			}
		});
	}

	if (!$.fn.nothidden) {
		$.fn.extend({
			nothidden: function() {
				$(this).removeClass('is-hidden');
				return this;
			}
		});
	}

	if (!$.fn.runsearch) {
		$.fn.extend({
			runsearch: function(force){
				var query = $(this).val();
				if (!query || query === '') {
					isNotSearch();
				} else if (force || query !== $(this).data('term_was')) {
					if (query.length >= 3) {
						$(this).searchwait();
						$search_clear.hidden();
						ctSearch(query);
						$(this).data('term_was', $(this).val());
					}
				}
				return this;
			}
		});
	}


	/**
	 * Do all the things
	 */
	$(document).ready(function() {

		$api_link = $('link[rel="https://api.w.org/"]');
		api_root = $api_link.attr('href');

		$postlist_container = $('[data-postlist-vorstoesse]');
		$count =  $('[data-filtered-postlist-count]'); // both!
		$count_list = $('[data-filtered-postlist-count="list"]');
		$count_search = $('[data-filtered-postlist-count="searchresults"]');
		$noresults = $('[data-filtered-postlist-no-results]');
		$searchresults = $('[data-filtered-searchresults]');
		$search_field = $('[data-postlist-search]');
		$search_clear = $('[data-postlist-search-clear]');
		$list = $('[data-filtered-postlist]');
		$filters = $('[data-postlist-filter]');
		$load_more = $('[data-postlist-button]');
		more_to_load = $(element + '.hidden').length;
		card_template = $('[data-post-list-jscard]').html();

		$load_more.on('click.listfilter', { source: 'button' }, filterList);
		$filters.on('change.listfilter', { source: 'filter' }, filterList);

		activateSearch();

		$('[data-postlist-search]').runsearch(true);

		getPrecog();

	});

}(jQuery));
