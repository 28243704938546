/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {

				let tes = $('.champion-content-wrap .container .row').isotope();
				setTimeout(function(){
					tes.isotope('layout');
				}, 1000);

				$('.scrollable-item').on('DOMSubtreeModified', function(){
					tes.isotope('layout');
				});

				$(document).ready(function () {
					$('input[type="url"]').change(function() {
						let notUrl = true;

						if(this.value.indexOf("http://") !== -1) {
							notUrl = false;
						}
						if(this.value.indexOf("https://") !== -1){
							notUrl = false;
						}
						if(notUrl){
							this.value = "https://" + this.value;
						}
					});
				});

				$('[data-max]').keydown( function(e){
					let max_chars = $(this).data('max');
					if ($(this).val().length >= max_chars) { 
						$(this).val($(this).val().substr(0, max_chars));
					}
				});
				
				$('[data-max]').keyup( function(e){
					let max_chars = $(this).data('max');
					if ($(this).val().length >= max_chars) { 
						$(this).val($(this).val().substr(0, max_chars));
					}
				});

				$('input[required], textarea[required], select[required]').each(function(){
					if($(this).prev().is('label')){
						$(this).prev().addClass('required');
					}
					if($(this).prev().is('[data-tooltip]') && $(this).prev().prev().is('label')){
						$(this).prev().prev().addClass('required');
					}
					if($(this).prev().prev().is('[data-tooltip]') && $(this).prev().is('br') && $(this).prev().prev().prev().is('label')){
						$(this).prev().prev().prev().addClass('required');
					}
				});

				var lastIndex = 0;
				var lastIndexNext = 0;

				function updateIndexes(cloneField, type){
					//each.clonefield:not(cloned)
					// while next is cloned
						// prev.index = prev cloned index
						// if prev.index == udnefined
							// this.index = 2
						// else
							//  this.index = prev.index + 1
						// next = this.next
					jQuery('[data-clonefield]:not([data-is-cloned])').each(function(){
						let element = jQuery(this);
						let max = 0;
						let offset = 1;

						if(type == 'remove'){
							let offset = -1;
						}

						do{
							let prevIndex = 1;
							if(typeof element.attr('data-clone-index') !== 'undefined'){
								prevIndex = parseInt(element.attr('data-clone-index'));
							}
							element = element.next();
							if(element.is('[data-is-cloned]')){
								element.attr('data-clone-index', parseInt(prevIndex) + offset);
								element.find('[name]').each(function(){
									var currentName = jQuery(this).attr('name');
									var number = prevIndex + offset;
									jQuery(this).attr('name', currentName.substr(0, currentName.length - number.toString().length) + '' + (number));
								});
							}
							max++;
						}while(element.next().is('[data-is-cloned]') && max < 100);
					});
				}

				function updateControlls(cloneField, type = 'added'){
					//determin the current index of the field
					var index = 1;
					var highestIndex = 0;
					var max = 0;
					var oldField = cloneField;

					do{
						max++;
						highestIndex++;
						
						cloneField = cloneField.next();
					}while(cloneField.next().is('[data-is-cloned]') && max < 100);


					updateIndexes(cloneField, type);
					if(cloneField.is('[data-clone-index]')){
						index = parseInt(cloneField.attr('data-clone-index'));
					}
					
					//check if min or max is exceeded
					if(cloneField.is('[data-max]') && type == 'added'){
						if(oldField == cloneField){
							cloneField.next().find('[data-clonefield-remove]').removeAttr('disabled');
							if(parseInt(cloneField.attr('data-max')) <= index + 1){
								cloneField.next().find('[data-clonefield-add]').attr('disabled', '');
								return false;
							}
						}else{
							cloneField.find('[data-clonefield-remove]').removeAttr('disabled');
							if(parseInt(cloneField.attr('data-max')) <= index){
								cloneField.find('[data-clonefield-add]').attr('disabled', '');
								return false;
							}
						}
						
					}
					if(cloneField.is('[data-min]') && type == 'removed'){
						cloneField.prev().find('[data-clonefield-add]').removeAttr('disabled');
						if(parseInt(cloneField.attr('data-min')) >= index - 1){
							cloneField.prev().find('[data-clonefield-remove]').attr('disabled', '');
							return false;
						}
					}
					
					return true;
				}

				jQuery(document).ready(function(){
				
					jQuery('body').on('click', '[data-clonefield-remove]', function(){
						if(!jQuery(this).is('[disabled]')){

							//remove the item and move the controls
							var previousItem = jQuery(this).parents('[data-is-cloned]').prev();
							var newControls = jQuery(this).parent().clone();

							if(previousItem.is('[data-is-cloned]')){
								previousItem.append(newControls);
							}else{
								newControls.find('[data-clonefield-remove]').attr('disabled', '');
								previousItem.append(newControls);
							}
							
							
							//remove the clonefield
							jQuery(this).parents('[data-is-cloned]').remove();

							updateControlls(jQuery(this).parents('[data-clonefield]'), 'removed');
						}
					});
					
					jQuery('body').on('click', '[data-clonefield-add]', function(){
						if(!jQuery(this).is('[disabled]')){
						
						// get the current itteration
						var newFields = jQuery(this).parent().parent().clone();
						var myRegexp = /(\d*)$/g;      
						
						newFields.find('[name]').each(function(){
							var currentName = jQuery(this).attr('name');
							var match = currentName.match(myRegexp);
							if(typeof match !== 'undefined' && match !== null && jQuery.isNumeric(match[0])){
								newFields.attr('data-clone-index', parseInt(match[0]) + 1);
								jQuery(this).attr('name', currentName.substr(0, currentName.length - match[0].length) + '' + (parseInt(match[0]) + 1));
							}else{
								newFields.attr('data-clone-index', 0);
								jQuery(this).attr('name', currentName + '-1');
							}
						});
						
						//add the new fields

						var succ = updateControlls(jQuery(this).parents('[data-clonefield]'), 'added');
						if(succ){
							newFields.attr('data-is-cloned', '');
							newFields.find('[data-clonefield-remove]').removeAttr('disabled');
							newFields.insertAfter(jQuery(this).parents('[data-clonefield]'));
						}
						//check if the min number of items has been reached -> disable if true
						
						
						//remove the old controller
						//jQuery(this).parent().remove();
						
						}
					});

					jQuery('[data-visible-on]').each(function(){
						$element = jQuery(this).prev();
						if($element.val() == $element.next().attr('data-visible-on')){
							$element.next().attr('name', $element.attr('name'));
							$element.next().css('display', 'block');
						}else{
							$element.next().removeAttr('name');
							$element.next().css('display', 'none');
						}
					});
					jQuery('[data-visible-on]').each(function(){
						$element = jQuery(this).prev();
						if($element.val() == $element.next().attr('data-visible-on')){
							$element.next().attr('required', '');
						}else{
							$element.next().removeAttr('required');
						}
					});
					jQuery('[data-visible-on]').prev().on('change', function(){
						if(jQuery(this).val() == jQuery(this).next().attr('data-visible-on')){
							jQuery(this).next().attr('name', jQuery(this).attr('name'));
							jQuery(this).next().css('display', 'block');
						}else{
							jQuery(this).next().removeAttr('name');
							jQuery(this).next().css('display', 'none');
						}
					});
					jQuery('[data-required-on]').prev().on('change', function(){
						if(jQuery(this).val() == jQuery(this).next().attr('data-visible-on')){
							jQuery(this).next().attr('required', '');
						}else{
							jQuery(this).next().removeAttr('required');
						}
					});
					
					
					jQuery('[data-clonefield]').each(function(){
						var controllerHtml = '<span class="clone-container"><span class="add" data-clonefield-add>+</span><span class="remove" data-clonefield-remove disabled >-</span></span>';

						//skip all elements that allready have a clone in the html
						if(jQuery(this).next().is('[data-is-cloned]')){
							//return;
						}
						jQuery(this).append(controllerHtml);

						if(jQuery(this).is('[data-is-cloned]')){
							jQuery(this).find('.remove').removeAttr('disabled');
						}
						
						if(jQuery(this).is('[data-min]')){
							var min = parseInt(jQuery(this).attr('data-min'));
							var i = 0;
							var element = this;
							for(i = 0; i != min - 1; i++){
								if(i == 0){
								jQuery(element).find('[data-clonefield-add]').trigger('click');
								}else{
								jQuery(element).next().find('[data-clonefield-add]').trigger('click');
								element = jQuery(element).next();
								}
							}
							jQuery(element).next().find('[data-clonefield-remove]').attr('disabled', '');
						}
					});
				});


				var ajaxLoader = new Ajax_Post_Loader('/wp-admin/admin-ajax.php', 'ajax_get_vorstoss', 12, $('.filter-container'));
				
				//ähnlich wie hasClass, gibt true zurück wenn ein teil der klasse vorhanden ist
				$.fn.hasPartialClass = function(partial) {
					var match = new RegExp(partial).test(this.prop('class'));
					return match;
				};
				if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
					$('select.gfield_select').selectpicker('mobile');
				} else {
					$('select.gfield_select').selectpicker({
						size: 4,
					});
					$('select.btn.btn_header').selectpicker();
				}
				jQuery('.js-scroll-to-newsletter').on('click', function(event) {
					event.preventDefault();
					jQuery("html, body").animate({
						scrollTop: jQuery(document).height()
					}, 1000);
				});
				jQuery(window).scroll(function() {
					var aTop = jQuery('.header_image').height();
					var scrollBottom = $(window).scrollTop() + $(window).height();
					if (scrollBottom >= ($(window).height() + aTop)) {
						jQuery(".sticky-header").addClass("on");
						jQuery(".sticky-meta").addClass("on");
					} else {
						jQuery(".sticky-header").removeClass("on");
						jQuery(".sticky-meta").removeClass("on");
					}
					if (scrollBottom >= ($(window).height() + aTop - 70)) {
						jQuery("header").addClass('sticky');
					} else {
						jQuery("header").removeClass('sticky');
					}
				});
				jQuery(document).ready(function() {
					var aTop = jQuery('.header_image').height();
					var scrollBottom = $(window).scrollTop() + $(window).height();
					if (scrollBottom >= ($(window).height() + aTop)) {
						jQuery(".sticky-header").addClass("on");
					} else {
						jQuery(".sticky-header").removeClass("on");
					}
					$('.post-type-archive-pd_vorstoss .js-loading').off("click.loadMore").on("click.loadMore", function() {
						$('.filter-container .card_holder.hidden').slice(0, 12).removeClass('hidden');
						init_isotope();
					});
					// Define the last visible menu point ("Über uns") right-aligned
					$(".sticky-header .navbar-nav > li:visible").last().addClass("dropdown-aligned-right");
				});
				
				jQuery(".show_filters, .hide_filters").click(function() {
					jQuery('.filters').toggleClass("shown");
					jQuery('.hide_filters').toggleClass("hidden");
					jQuery(".show_filters").toggleClass("hidden");
				});
			
			//adjust logo size on partner template
			$('.image-container').height($('.image-container').width());
			$(window).resize(function() {
				$('.image-container').height($('.image-container').width());
			});
			if (jQuery(window).width() < 991) {
				jQuery('.btn_filter.show_filters').click(function() {
					jQuery('.row.filters').addClass('open');
				});
				jQuery('.hide_filters').click(function() {
					jQuery('.row.filters').removeClass('open');
				});
			}

			
		},
		finalize: function() {
			// JavaScript to be fired on all pages, after page specific JS is fired
		}
	},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		},
};
// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
	fire: function(func, funcname, args) {
		var fire;
		var namespace = Sage;
		funcname = (funcname === undefined) ? 'init' : funcname;
		fire = func !== '';
		fire = fire && namespace[func];
		fire = fire && typeof namespace[func][funcname] === 'function';
		if (fire) {
			namespace[func][funcname](args);
		}
	},
	loadEvents: function() {
		// Fire common init JS
		UTIL.fire('common');
		// Fire page-specific init JS, and then finalize JS
		$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
			UTIL.fire(classnm);
			UTIL.fire(classnm, 'finalize');
		});
		// Fire common finalize JS
		UTIL.fire('common', 'finalize');
	}
};
// Load Events
$(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.