jQuery(document).ready(function( jQuery )
{

    function transform( ele, value ) {
        ele.css({
            '-webkit-transform' : 'translate(' + value + ')',
            '-moz-transform'    : 'translate(' + value + ')',
            '-ms-transform'     : 'translate(' + value + ')',
            '-o-transform'      : 'translate(' + value + ')',
            'transform'         : 'translate(' + value + ')'
        });
    }

    /*
     * Responsive Nav
     */

    var defaultOpts = {
        maxWindowWidth : '981',
        parentLinkClickable : true
    };

    var isClickHandlerBound = false;

    var opts = defaultOpts;

    var mainMenu = jQuery('.navbar-nav.navbar-right');

    var currentMenuDepth = 0;

    var hasSubMenuItems = jQuery('li.dropdown > a');

    var subMenuClickHandler = function ( e ) {
        e.preventDefault();

        // Save Element in Variable to append to menu
        var parentMenuItem = jQuery(this).parent();
        var parentMenuLink = jQuery(this);
        var subMenu = jQuery(' ul.dropdown-menu', parentMenuItem);

        if( opts.parentLinkClickable ) {
            subMenu.prepend( '<li class="parent-link">' + parentMenuLink.get(0).outerHTML + '</li>' );
        }

        jQuery('.parent-link a').removeAttr('data-toggle');
        jQuery('.parent-link a').removeAttr('class');

        subMenu.prepend( '<li class="btn-back"><span>Zurück</span></li>' );

        //parentMenuItem.addClass('drop-active');

        // Increase Menu Depth
        currentMenuDepth++;

        var menuTranslation = currentMenuDepth * 100;

        transform( mainMenu, -menuTranslation + '%' );
    };


    mainMenu.on('click', '.btn-back', function() {
        jQuery(this).parent('ul').parent('li').removeClass('drop-active');
        jQuery(this).siblings('.parent-link').remove();
        jQuery(this).remove();

        currentMenuDepth--;

        var menuTranslation = currentMenuDepth * 100;

        transform( mainMenu, -menuTranslation + '%' );
    });

    jQuery(window).on('load resize', function(){

        if (jQuery(window).width() > opts.maxWindowWidth){
            // Remove all parent links
            jQuery( '.parent-link, .btn-back, .drop-active' ).remove();
            if( isClickHandlerBound ) {
                hasSubMenuItems.unbind('click', subMenuClickHandler);
                isClickHandlerBound = false; 
            }
            currentMenuDepth = 0;
            transform( mainMenu, '0%' );
        } else {
            if( !isClickHandlerBound ) {
                hasSubMenuItems.bind( 'click', subMenuClickHandler );
                isClickHandlerBound = true;
            }

        }

    });


});