function Ajax_Post_Loader( url, action, startoffset, ajaxcanvas ) {
	this.url = url;
	this.action = action;
	this.offset = this.startoffset = startoffset;

	this.ajaxcanvas = ajaxcanvas;

	this.filters = {};

	this.ajaxCall = null;

	this.loadMore = true;

	this.callback = null;
}


Ajax_Post_Loader.prototype.setCallback = function( callback ) {
	this.callback = callback;
};

Ajax_Post_Loader.prototype.executeCallback = function( loading, hasnext ) {
	if ( this.callback && typeof(this.callback) === "function") {
		this.callback( loading, hasnext );
	}
};

Ajax_Post_Loader.prototype.resetFilters = function() {
	this.filters = {};
	this.update();
};

Ajax_Post_Loader.prototype.loadPosts = function( posts_amount, postCategory ) {

	var self_instance = this;

	self_instance.executeCallback( 'loading' );

	this.ajaxCall = jQuery.ajax({
		url: self_instance.url,
		data: {action: self_instance.action, filters : JSON.stringify(self_instance.filters), amount : posts_amount, postCat : postCategory },
		success: function( result ) {
			jQuery(self_instance.ajaxcanvas).fadeOut( 'fast', function() {
				jQuery(self_instance.ajaxcanvas).empty().append( result.html ).fadeIn();
			});
			self_instance.offset = posts_amount;
			self_instance.loadMore = result.loadMore;
			self_instance.executeCallback( 'loaded', result.loadMore );
		},
		dataType: 'json'
	});
};

Ajax_Post_Loader.prototype.loadMorePosts = function( posts_amount, postCategory ) {

	var self_instance = this;
	self_instance.executeCallback( 'loading' );

	if( this.loadMore === false ) {
		setTimeout(function() {
			self_instance.executeCallback( 'loaded', this.loadMore );
		}, 500);

		return;
	}

	var offset = this.offset;
	this.ajaxCall = jQuery.ajax({
		url: this.url,
		data: {action: this.action, filters : JSON.stringify(this.filters), amount : posts_amount, offset: this.offset, postCat : postCategory },
		success: function( result ) {
			jQuery(self_instance.ajaxcanvas).append( result.html );
			self_instance.offset = self_instance.offset + posts_amount;

			self_instance.loadMore = result.loadMore;

			self_instance.executeCallback( 'loaded', result.loadMore );
		},
		dataType: 'json'
	});
};

Ajax_Post_Loader.prototype.cancelAjax = function() {
	if( this.ajaxCall !== null ) {
		this.executeCallback( 'loaded' );
		this.ajaxCall.abort();
	}
};

Ajax_Post_Loader.prototype.update = function() {
	this.offset = this.startoffset;
	this.loadMore = true;
	this.loadPosts( this.startoffset );
};

Ajax_Post_Loader.prototype.addFilter = function( name, filter ) {

	this.cancelAjax();

	var namespace = name.split('.');

	if( namespace.length > 1 ) {
		if( this.filters[namespace[0]] === undefined ) {
			this.filters[namespace[0]] = {};
		}
		this.filters[namespace[0]][namespace[1]] = filter;
	}
	else {
		this.filters[namespace[0]] = filter;
	}

	jQuery(this.ajaxcanvas).empty();

	this.update();

};

Ajax_Post_Loader.prototype.setFilter = function( name, values, update ) {

	this.cancelAjax();

	this.filters[name] = values;

	if (update) {
		this.update();
	}
};

Ajax_Post_Loader.prototype.removeFilter = function( filter ) {

	this.cancelAjax();

	var namespace = filter.split('.');

	var index = -1;

	if( namespace.length > 1 ) {
		index = this.filters[namespace[0]].indexOf(namespace[1]);

		if (index > -1) {
		    this.filters[namespace[0]].splice(index, 1);
		}
	}
	else {
		index = this.filters.indexOf(namespace[1]);

		if (index > -1) {
		    this.filters.splice(index, 1);
		}

	}
};
