(function($) {
    var flagDataScrolling = false;

    $(document).on('ready', function(){
        var initialyActiveItems = $('.filter-item.active');
        if(initialyActiveItems.length > 0){
            initialyActiveItems.trigger('click');
        }

        // check url for existing filters, set them and refresh the view
        const currentSearchParams = new URLSearchParams(window.location.search);
        const currentSearchParamString = currentSearchParams.toString();
        if (currentSearchParamString !== '') {
            currentSearchParams.forEach((value, key) => {
                console.log('key', key);
                console.log('value', value);
                switch (key) {
                    case 'filterCanton':
                        const cantonFilter = document.querySelector('[data-filter-canton="select"]');
                        if (cantonFilter) {
                            cantonFilter.value = value;
                            $(cantonFilter).selectpicker('render');
                        }
                        break;
                    case 'filterParty':
                        const partyFilter = document.querySelector('[data-filter-party="select"]');
                        if (partyFilter) {
                            partyFilter.value = value;
                            $(partyFilter).selectpicker('render');
                        }
                        break;
                    case 'filterYear':
                        const yearFilter = document.querySelector('[data-filter-year="select"]');
                        if (yearFilter) {
                            yearFilter.value = value;
                            $(yearFilter).selectpicker('render');
                        }
                        break;
                    case 'filterElection':
                        const electionFilter = document.querySelector('[data-filter-election="select"]');
                        if (electionFilter) {
                            electionFilter.value = value;
                            $(electionFilter).selectpicker('render');
                        }
                        break;
                    default:
                        return;
                        break;
                }

                onClickFilterCantonOrParty(null, null);
            })
        }
    });

    $('[data-scroll-up]').on('click', function() {
        if ($(this).hasClass('inactive')) {
            return;
        }

        if (flagDataScrolling) {
            // Last event not finished. Just ignore this one ...
            return;
        }
        flagDataScrolling = true;

        var $container = $(this).parent();
        if ($container.attr('data-active-index')) {
            // Activities or Initiatives
            var currentElementIndex = parseInt($container.attr('data-active-index'));
            var $allElements = $container.find('.scrollable-item');
            var $previousElement = $($allElements[currentElementIndex - 1]);
            var previousElement = $previousElement.get();
            var $firstElement = $container.find('.scrollable-inner > .scrollable-item:first-child');
            var scrollHeight = previousElement[0].offsetHeight + parseInt($previousElement.css('margin-bottom'));
            var marginTop = parseInt($firstElement.css('margin-top'));

            $container.find('[data-scroll-down]').removeClass('inactive');
            $firstElement.css('margin-top', (marginTop + scrollHeight) + 'px');
            $container.attr('data-active-index', currentElementIndex - 1);

            if ($($allElements[currentElementIndex - 2]).length < 1) {
                $(this).addClass('inactive');
            }
        }
        else {
            // IFrame for Social Wall
            var $iframeElement = $container.find("iframe");
            if ($iframeElement.length) {
                var containerHeight = $container.height();
                var scrollHeightPcent = 0.75 * containerHeight;   // 75%
                var marginTopDivIframe = parseInt($iframeElement.parent().css('margin-top'));
                var newMarginTop = Math.min(marginTopDivIframe + scrollHeightPcent, 0);
                $iframeElement.parent().css('margin-top', newMarginTop + 'px');
                $container.find('[data-scroll-down]').removeClass('inactive');

                if (newMarginTop === 0) {
                    $(this).addClass('inactive');
                }
            }
        }

        // After 300ms (time for transition defined in CSS, before to accept the next scrolling)
        setTimeout(function() {
            flagDataScrolling = false;
        }, 300);
    });

    $('[data-scroll-down]').on('click', function() {

        if ($(this).hasClass('inactive')) {
            return;
        }

        if (flagDataScrolling) {
            // Last event not finished. Just ignore this one ...
            return;
        }
        flagDataScrolling = true;

        var $container = $(this).parent();
        if ($container.attr('data-active-index')) {
            // Activities or Initiatives
            var currentElementIndex = parseInt($container.attr('data-active-index'));
            var $allElements = $container.find('.scrollable-item');
            var $currentElement = $($allElements[currentElementIndex]);
            var $firstElement = $container.find('.scrollable-inner > .scrollable-item:first-child');
            var scrollHeight = $currentElement.outerHeight(true);
            var marginTop = parseInt($firstElement.css('margin-top'));

            var $innerElement = $container.find('.scrollable-inner');
            var $lastElement = $container.find('.scrollable-inner > .scrollable-item:last-child');
            var bottomInnerElement = $innerElement.position().top + $innerElement.height();
            var bottomLastElement = $lastElement.position().top + $lastElement.height();

            $container.find('[data-scroll-up]').removeClass('inactive');
            $container.attr('data-active-index', currentElementIndex + 1);
            $firstElement.css('margin-top', (marginTop - scrollHeight) + 'px');

            if ($($allElements[currentElementIndex + 2]).length < 1) {
                $(this).addClass('inactive');
            }

            if (bottomLastElement - scrollHeight <= bottomInnerElement) {
                $(this).addClass('inactive');
            }
        }
        else {
            // IFrame for Social Wall
            var $iframeElement = $container.find("iframe");
            if ($iframeElement.length) {
                var containerHeight = $container.height();
                var divIframeHeight = $iframeElement.parent().height();
                var scrollHeightPcent = 0.75 * containerHeight;   // 75%
                var marginTopDivIframe = parseInt($iframeElement.parent().css('margin-top'));
                var newMarginTop = Math.max(marginTopDivIframe - scrollHeightPcent, containerHeight - divIframeHeight);
                $iframeElement.parent().css('margin-top', newMarginTop + 'px');
                $container.find('[data-scroll-up]').removeClass('inactive');

                if (newMarginTop === containerHeight - divIframeHeight) {
                    $(this).addClass('inactive');
                }
            }
        }

        // After 300ms (time for transition defined in CSS, before to accept the next scrolling)
        setTimeout(function() {
            flagDataScrolling = false;
        }, 300);
    });

    function championAllCardsWithSameHeight() {
        var layoutArr = [];
        $items = $(".champion-card-wrapper");
        $(".champion-card", $items).css("height", "");

        $items.each(function(index, item) {
            if ($(item).is(":visible")) {
                var posTop = $(item).position().top;
                if (typeof layoutArr[posTop] === 'undefined') {
                    layoutArr[posTop] = [];
                }
                $card = $(".champion-card", $(item));
                layoutArr[posTop].push($card);
            }
        });

        layoutArr.forEach(function($cards) {
            var maxHeight = 0;
            $cards.forEach(function($card) {
                maxHeight = Math.max($card.height(), maxHeight);
            });
            $cards.forEach(function($card) {
                $card.height(maxHeight);
            });
        });
    }

    // filters
    function onClickFilterCantonOrParty(dataFilter, clickedElement) {
        var cantonFilter = document.querySelector('[data-filter-canton="select"]');
        var filterCanton = cantonFilter.value;

        var partyFilter = document.querySelector('[data-filter-party="select"]');
        var filterParty = partyFilter.value;

        var yearFilter = document.querySelector('[data-filter-year="select"]');
        var filterYear = yearFilter.value;

        var electionFilter = document.querySelector('[data-filter-election="select"]');
        var filterElection = electionFilter.value;

        const filters = {};

        if (filterCanton !== '*') {
            filters.filterCanton = [filterCanton];
        }

        if (filterParty !== '*') {
            filters.filterParty = [filterParty];
        }

        if (filterYear !== '*') {
            filters.filterYear = [filterYear];
        }

        if (filterElection !== '*') {
            filters.filterElection = [filterElection];
        }

        const paramString = new URLSearchParams(filters).toString();
        let replaceUrl = location.href.split('?')[0];
            if (paramString !== '') {
                replaceUrl += `?${paramString}`;
            }

            history.replaceState(null, '', replaceUrl);

        // generate a query string based on filters, then push it to the url

        $("[data-filter-item]").each(function() {
            var filterCantonItem = $(this).attr("data-filter-canton-item").split(',');
            var filterPartyItem = $(this).attr("data-filter-party-item").split(',');
            var filterElectionItem = $(this).attr("data-filter-election-item").split(',');
            var filterYearItem = $(this).attr("data-filter-year-item").split(',');

            console.log(filterElectionItem);

            if ( ((filterCanton === "*") || (filterCantonItem.includes(filterCanton))) &&
                 ((filterParty  === "*") || (filterPartyItem.includes(filterParty) )) &&
                 ((filterYear  === "*") || (filterYearItem.includes(filterYear) )) &&
                 ((filterElection  === "*") || (filterElectionItem.includes(filterElection) ))    )
            {
                $(this).show();
            }
            else {
                $(this).hide();
            }
        });

        $('.champion-container').isotope({
            itemSelector: ".champion-card-wrapper",
            layoutMode:   "fitRows",
        });
        // Isotope Event "layoutComplete" is not triggered but "queued", no idea why !
        // Therefore make it manually, but after the transition is finished (0.4s by Isotope)
        setTimeout(championAllCardsWithSameHeight, 450);
        setTimeout(function() {
            championAllCardsWithSameHeight();
            $('.champion-container').isotope("layout");
        }, 700);   // Just for security ...
    }
    if ($(window).width() > 767) {
        $('[data-filter-canton="select"]').on('change', function() {
            onClickFilterCantonOrParty("[data-filter-canton]", this);
        });
        $('[data-filter-party="select"]').on('change', function() {
            onClickFilterCantonOrParty("[data-filter-party]", this);
        });
        $('[data-filter-election="select"]').on('change', function() {
            onClickFilterCantonOrParty("[data-filter-election]", this);
        });
        $('[data-filter-year="select"]').on('change', function() {
            onClickFilterCantonOrParty("[data-filter-year]", this);
        });
    } else {
        $('[data-filter-canton="select"]').on('change', function() {
            if ($(this).parent().hasClass('open')) {
                onClickFilterCantonOrParty("[data-filter-canton]", this);
                $(this).parent().prepend($(this));
            }
            $(this).parent().toggleClass('open');
        });
        $('[data-filter-party="select"]').on('change', function() {
            if ($(this).parent().hasClass('open')) {
                onClickFilterCantonOrParty("[data-filter-party]", this);
                $(this).parent().prepend($(this));
            }
            $(this).parent().toggleClass('open');
        });
        $('[data-filter-election="select"]').on('change', function() {
            if ($(this).parent().hasClass('open')) {
                onClickFilterCantonOrParty("[data-filter-election]", this);
                $(this).parent().prepend($(this));
            }
            $(this).parent().toggleClass('open');
        });
        $('[data-filter-year="select"]').on('change', function() {
            if ($(this).parent().hasClass('open')) {
                onClickFilterCantonOrParty("[data-filter-year]", this);
                $(this).parent().prepend($(this));
            }
            $(this).parent().toggleClass('open');
        });
    }

    lightbox.option({
        'resizeDuration': 200,
    });

    // display all the champions in a correct layout
    $(document).ready(function () {
        var $grid = $('.champion-container');
        $grid.on("layoutComplete", championAllCardsWithSameHeight);
        $grid.isotope({
            itemSelector: ".champion-card-wrapper",
            layoutMode:   "fitRows",
        });
    });
})(jQuery);
